import { useNavigate } from "react-router-dom";
import './History.css';
import React from "react";
import { useLocation } from "react-router-dom";
import { FC, useEffect, useState } from 'react';
import axis from 'axios';
import { Bars } from 'react-loader-spinner'

type history ={
  order: string;
  uid: string;
  send_date: string;
  post_uid: string;
  organization_id: string;
  send_delete_flg: boolean;
  receive_delete_flg: boolean;
  name: string;
  notice: {
    latitude: string;
    longitude: string;
    mode: boolean;
    message: string;
    unread: boolean;
  }
}

const History: FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const [isAppShow, setIsAppShow] = useState(false)
  const navigate = useNavigate()
  const { state } = useLocation()

  const search = useLocation().search

  const query = new URLSearchParams(search)

  var uid = "none"
  var organizationId = "none"
  var os = "none"
  var name = "none"
  var icon = "none"
  var token = "none"

  if (query.has('show')) {
    uid = query.get('uid') || "none"
    organizationId = query.get('organization_id') || "none"
    os = query.get('os') || "none"
    name = query.get('show') || "none"
  }else {
    uid = state.uid
    organizationId = state.organizationId
    os = state.os
    name = state.name
    icon = state.icon
    token = state.token
  }

  const handleMenu = () => {
      navigate('/?organization_id='+organizationId+'&uid='+uid+'&os='+os, {
          state: {
              viewState: true,
              name: state.name || "none",
              icon: state.icon || "none",
              token: state.token || "none",
          }
      })
  }

  const [activeTab, setActiveTab] = useState("receive");
  const sendActive = () => {
    setActiveTab("send")
  }
  const receiveActive = () => {
    setActiveTab("receive")
  }

  const defaultSendHistory: history = {
    order: "",
    uid: "",
    send_date: "",
    post_uid: "",
    organization_id: "",
    send_delete_flg: false,
    receive_delete_flg: false,
    name: "",
    notice: {
      latitude: "",
      longitude: "",
      mode: false,
      message: "送信した通知はありません",
      unread: false
    }
  }
  const defaultReceiveHistory: history = {
    order: "",
    uid: "",
    send_date: "",
    post_uid: "",
    organization_id: "",
    send_delete_flg: false,
    receive_delete_flg: false,
    name: "",
    notice: {
      latitude: "",
      longitude: "",
      mode: false,
      message: "受信した通知はありません",
      unread: false
    }
  }
  const [notSendHistory, setNotSendHistory] = useState(false)
  const [notReceiveHistory, setNotReceiveHistory] = useState(false)
  const [sendHistoryList, setSendHistoryList] = useState<history[]>([defaultSendHistory]);
  const [receiveHistoryList, setReceiveHistoryList] = useState<history[]>([defaultReceiveHistory]);
  var sendHistoryCount = 0
  var receiveHistoryCount = 0
  var sendDeleteFlgCount = 0
  var receiveDeleteFlgCount = 0
  var dataSendHistory: history[] = []
  var dataReceiveHistory: history[] = []
  var taskCount = 2;
  // setIsLoggedIn(false)
  React.useLayoutEffect(() => {

    if (query.has('show')) {
      setIsAppShow(true)
    }else if (state.isAppShow){
      setIsAppShow(true)
    }

    var getUid = ''
    var getOrganizationId = ''
    var getUrl = ''

    const sendHistoryUrl = 'https://location-sharing-api-stg.ap.litcity.ne.jp/history/send?organization_id='+organizationId+'&uid='+uid;
    axis.get(sendHistoryUrl)
    .then((response) => {
      dataSendHistory = response.data;
      sendDeleteFlgCount = 0
      sendHistoryCount = dataSendHistory.length
      if (dataSendHistory.length == 0 || response.data == "none"){
        setNotSendHistory(true)
        taskCount--;
        if (taskCount == 0) {
          setIsLoggedIn(false)
        }
      }else {
        for (let i = 0; i < dataSendHistory.length; i++) {
          if (dataSendHistory[i].send_delete_flg){
            sendHistoryCount--;
            sendDeleteFlgCount++;
            if (dataSendHistory.length == sendDeleteFlgCount){
              setNotSendHistory(true)
            }
            if (sendHistoryCount == 0){
              setSendHistoryList(dataSendHistory);
              taskCount--;
              if (taskCount == 0) {
                setIsLoggedIn(false)
              }
            }
          }else {
            getUid = 'uid='+dataSendHistory[i].uid;
            getOrganizationId = 'organization_id='+dataSendHistory[i].organization_id;
            getUrl = 'https://location-sharing-api-stg.ap.litcity.ne.jp/users/?'+getOrganizationId+'&'+getUid+'&puid=puid';

            axis.get(getUrl).then((response) => {
              const user = response.data;
              if (user.hasOwnProperty('name')){
                dataSendHistory[i].name = user.name
                sendHistoryCount--;
                if (sendHistoryCount == 0){
                  setSendHistoryList(dataSendHistory);
                  taskCount--;
                  if (taskCount == 0) {
                    setIsLoggedIn(false)
                  }
                }
              }else {
                sendHistoryCount--;
                if (sendHistoryCount == 0){
                  setSendHistoryList(dataSendHistory);
                  taskCount--;
                  if (taskCount == 0) {
                    setIsLoggedIn(false)
                  }
                }
              }
            });
          }
        }
      }
    })
    .catch((error) => {
      taskCount--;
      if (taskCount == 0) {
        setIsLoggedIn(false)
      }
    });

    const receiveHistoryUrl = 'https://location-sharing-api-stg.ap.litcity.ne.jp/history/receive?organization_id='+organizationId+'&uid='+uid;
    axis.get(receiveHistoryUrl).then((response) => {
      dataReceiveHistory = response.data;
      receiveDeleteFlgCount = 0
      receiveHistoryCount = dataReceiveHistory.length
      if (receiveHistoryCount == 0 || response.data == "none"){
        setNotReceiveHistory(true)
        taskCount--;
        if (taskCount == 0) {
          setIsLoggedIn(false)
        }
      }else {
        for (let i = 0; i < dataReceiveHistory.length; i++) {
          if (dataReceiveHistory[i].receive_delete_flg){
            receiveHistoryCount--;
            receiveDeleteFlgCount++;
            if (dataReceiveHistory.length == receiveDeleteFlgCount){
              setNotReceiveHistory(true)
            }
            if (receiveHistoryCount == 0){
              setReceiveHistoryList(dataReceiveHistory);
              taskCount--;
              if (taskCount == 0) {
                setIsLoggedIn(false)
              }
            }
          }else {
            getUid = 'uid='+dataReceiveHistory[i].uid;
            getOrganizationId = 'organization_id='+dataReceiveHistory[i].organization_id;
            getUrl = 'https://location-sharing-api-stg.ap.litcity.ne.jp/users/?'+getOrganizationId+'&'+getUid+'&puid=puid';

            axis.get(getUrl).then((response) => {
              const user = response.data;
              if (user.hasOwnProperty('name')){
                dataReceiveHistory[i].name = user.name
                receiveHistoryCount--;
                if (receiveHistoryCount == 0){
                  setReceiveHistoryList(dataReceiveHistory);
                  taskCount--;
                  if (taskCount == 0) {
                    setIsLoggedIn(false)
                  }
                }
              }else {
                receiveHistoryCount--;
                if (receiveHistoryCount == 0){
                  setReceiveHistoryList(dataReceiveHistory);
                  taskCount--;
                  if (taskCount == 0) {
                    setIsLoggedIn(false)
                  }
                }
              }
            });
          }
        }
      }
    })
    .catch((error) => {
      taskCount--;
      if (taskCount == 0) {
        setIsLoggedIn(false)
      }
    });

  }, []);

  const handleSendDetail = (arrayNo: number) => {
    navigate('/Detail?organization_id='+organizationId+'&uid='+uid+'&uid='+uid, {
        state: {
            viewState: true,
            name: name || "none",
            icon: icon || "none",
            token: token || "none",
            uid: uid,
            os: os,
            organizationId: organizationId || "none",
            history: sendHistoryList[arrayNo],
            type: "送信",
            isAppShow: isAppShow
        }
    })
  }
  const handleReceiveDetail = (arrayNo: number) => {
    navigate('/Detail?organization_id='+organizationId+'&uid='+uid+'&uid='+uid, {
        state: {
            viewState: true,
            name: name || "none",
            icon: icon || "none",
            token: token || "none",
            uid: uid,
            os: os,
            organizationId: organizationId || "none",
            history: receiveHistoryList[arrayNo],
            type: "受信",
            isAppShow: isAppShow
        }
    })
  }
  // const handleSendDelete = (arrayNo: number) => {
  // }
  // const handleReceiveDelete = (arrayNo: number) => {
  // }

  return (
      <div className="History">
      <header className="History-header">
        {isAppShow ? (
            ""
        ): (
          <div className="History-header-back" onClick={handleMenu}>
            <span className="dli-chevron-round-left"></span>
          </div>
        )}
        <div className="History-header-title font-navi-title">
          通知履歴
        </div>
      </header>
      {isLoggedIn ? (
            <div className="History-cover">
                <div className="History-cover-oval">
                    <Bars
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            </div>
        ) : (
            ""
        )}
      <body className="History-body">
        <div className="History-tub">
          {activeTab=="send" ? (
              <div className="History-tub-item">
                  <div className="History-tub-item-active font-contents-sub-title">
                  送信
                  </div>
              </div>
          ) : (
            <div className="History-tub-item font-contents-sub-title" onClick={sendActive}>
              送信
            </div>
          )}
          {activeTab=="receive" ? (
              <div className="History-tub-item">
                  <div className="History-tub-item-active font-contents-sub-title">
                  受信
                  </div>
              </div>
          ) : (
            <div className="History-tub-item font-contents-sub-title"  onClick={receiveActive}>
              受信
            </div>
          )}
        </div>
        <div className="History-contents">
          {activeTab=="send" ? (
            <div>
            {sendHistoryList.map((sendHistory, index: number) => (
              <div>
                {index != 0 ? (
                  <div>
                  {sendHistory.send_delete_flg ? (
                    ""
                  ) : (
                    <div className="History-item-top"></div>
                  )}
                  </div>
                ) : (
                  ""
                )}
                <div>
                  {notSendHistory ? (
                    <div>
                      {index == 0 ? (
                        <div className="History-item">
                          <div className="History-item-date font-contents-history-time"></div>
                          <div className="History-item-message font-contents-history-title">送信した通知はありません</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div>
                      {sendHistory.send_delete_flg ? (
                        ""
                      ) : (
                        <div>
                          {sendHistory.notice.message == "送信した通知はありません" ? (
                            <div className="History-item">
                              <div className="History-item-date font-contents-history-time">{sendHistory.send_date}</div>
                              <div className="History-item-message font-contents-history-title">{sendHistory.notice.message}</div>
                            </div>
                          ) : (
                            <div className="History-item" onClick={() => handleSendDetail(index)}>
                              <div className="History-item-date font-contents-history-time">{sendHistory.send_date}</div>
                              <div className="History-item-message font-contents-history-title">{sendHistory.name}</div>
                              <div className="History-item-message font-contents-history-title">{sendHistory.notice.message}</div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
            </div>
          ) : (
            <div>
            {receiveHistoryList.map((receiveHistory, index: number) => (
              <div>
                {index != 0 ? (
                  <div>
                    {receiveHistory.receive_delete_flg ? (
                      ""
                    ) : (
                      <div className="History-item-top"></div>
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div>
                  {notReceiveHistory ? (
                    <div>
                      {index == 0 ? (
                        <div className="History-item">
                          <div className="History-item-date font-contents-history-time"></div>
                          <div className="History-item-message font-contents-history-title">受信した通知はありません</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div>
                      {receiveHistory.receive_delete_flg ? (
                        ""
                      ) : (
                        <div>
                        {receiveHistory.notice.message == "受信した通知はありません" ? (
                          <div className="History-item">
                            <div className="History-item-date font-contents-history-time">{receiveHistory.send_date}</div>
                            <div className="History-item-message font-contents-history-title">{receiveHistory.notice.message}</div>
                          </div>
                        ) : (
                          <div className="History-item" onClick={() => handleReceiveDetail(index)}>
                            <div className="History-item-date font-contents-history-time">{receiveHistory.send_date}</div>
                            <div className="History-item-message font-contents-history-title">{receiveHistory.name}</div>
                            <div className="History-item-message font-contents-history-title">{receiveHistory.notice.message}</div>
                          </div>
                        )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
            </div>
          )}
        </div>
        {/* {activeTab=="send" ? (
          <div className="History-delete-button" onClick={() => handleSendDetail(index)}>
            <img src="./images/delete.png" alt="" />
          </div>
        ) : (
          <div className="History-delete-button" onClick={() => handleSendDetail(index)}>
            <img src="./images/delete.png" alt="" />
          </div>
        )} */}
      </body>
  </div>
  );
};
export default History;


